import {
  arrayRemove,
  doc,
  deleteField,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import {
  ChatDocument,
  ChatInput,
  ChatMessageDocument,
  chatSchema,
} from '@models/chat';
import { OrderDocument } from '@models/order';
import {
  FirebaseCallable,
  db,
  functions,
  httpsCallable,
  storage,
} from '@util/firebase';
import { logError } from '@util/logError';
import { ContactUsForm } from 'app/contact/components/ContactUs';
import {
  CollectionReference,
  collection,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  setDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { ProductDocument } from 'models/product';
import { initiateReturn } from '../orders';

export const messagesRef = collection(
  db,
  'messages'
) as CollectionReference<ChatDocument>;

export function getChatsByUidQuery(
  uid: string,
  variant?: 'messages' | 'offers' | 'orderDetail' | 'experts'
) {
  return query(
    messagesRef,
    where('uids', 'array-contains', uid),
    where('is_offer', '==', variant === 'offers'),
    orderBy('last_time', 'desc'),
    limit(100)
  );
}

export function getExpertChatsQuery(uid?: string) {
  return query(
    messagesRef,
    where('is_expert', '==', true),
    ...(uid && uid !== process.env.NEXT_PUBLIC_SUPPORT_ID
      ? [where('seller_id', '==', uid)]
      : []),
    orderBy('last_time', 'desc'),
    limit(1000)
  );
}

export function getChatById(chat_id: string) {
  return doc(messagesRef, chat_id);
}

export async function getChatDocumentById(chat_id: string) {
  const snapshot = await getDoc(getChatById(chat_id));
  const chat: ChatDocument | undefined = snapshot.data();
  if (!chat) {
    return null;
  }
  return chat;
}

export async function getChatByCaseNumber(case_num: number) {
  const q = query(messagesRef, where('case_num', '==', case_num), limit(1));
  const snap = await getDocs(q);
  const doc = snap.docs[0]?.data() as ChatDocument | undefined;
  return doc;
}

export async function getChatsForOrderDetails(order: OrderDocument) {
  const promises = [getChatsByOrderId(order.id)];
  if (order.product_ids && order.seller_arr)
    promises.push(
      getChatsByProductIds(order.product_ids, order.seller_arr, order.buyer_id)
    );
  const chats = (await Promise.all(promises))
    .flat()
    .sort((a, b) => b.last_time - a.last_time);
  return chats;
}

export async function getChatsForSellerAdminControls(uid: string) {
  try {
    const q = query(
      messagesRef,
      where('uids', 'array-contains', uid),
      orderBy('last_time', 'desc')
    );
    const docs = await getDocs(q);
    return docs.docs.map((doc) => doc.data());
  } catch (e) {
    logError(e as Error);
    return [];
  }
}
async function getChatsByProductIds(
  products: string[],
  seller_ids: string[],
  buyer_id: string
) {
  try {
    const q = query(
      messagesRef,
      where('product_id', 'in', products),
      where('buyer_id', '==', buyer_id)
    );
    const docs = await getDocs(q);
    return docs.docs
      .map((doc) => doc.data())
      .filter((chat) => seller_ids.includes(chat.seller_id));
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function getChatsByOrderId(order_id: string) {
  const q = query(
    messagesRef,
    where('order_id', '==', order_id),
    orderBy('last_time', 'desc')
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getTotalAdminBuyerChats() {
  try {
    const q = query(
      messagesRef,
      where('seller_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('last_time', '>=', 1688011200000), // launch date
      orderBy('last_time', 'desc')
    );
    const autoQ = query(
      messagesRef,
      where('seller_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('last_time', '>=', 1688011200000), // launch date,
      where('is_auto', '==', true),
      orderBy('last_time', 'desc')
    );
    const [count, autoCount] = await Promise.all([
      getCountFromServer(q),
      getCountFromServer(autoQ),
    ]);
    return count.data().count - autoCount.data().count;
  } catch (e) {
    logError(e as Error);
    return 0;
  }
}

export async function getTotalAdminSellerChats() {
  try {
    const q = query(
      messagesRef,
      where('buyer_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('last_time', '>=', 1688011200000), // launch date
      orderBy('last_time', 'desc')
    );
    const autoQ = query(
      messagesRef,
      where('buyer_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
      where('last_time', '>=', 1688011200000), // launch date,
      where('is_auto', '==', true),
      orderBy('last_time', 'desc')
    );
    const [count, autoCount] = await Promise.all([
      getCountFromServer(q),
      getCountFromServer(autoQ),
    ]);
    return count.data().count - autoCount.data().count;
  } catch (e) {
    logError(e as Error);
    return 0;
  }
}

export async function getTwentyChats(lastChatTime?: number) {
  if (lastChatTime) {
    return await getNextTwenty(lastChatTime);
  }
  const q = query(messagesRef, orderBy('last_time', 'desc'), limit(20));
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getNextTwenty(lastChatTime: number) {
  const q = query(
    messagesRef,
    orderBy('last_time', 'desc'),
    startAfter(lastChatTime),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getTwentySellerChats(lastChatTime?: number) {
  if (lastChatTime) {
    return await getNextTwentySeller(lastChatTime);
  }
  const q = query(
    messagesRef,
    where('buyer_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('last_time', '>=', 1688011200000), // launch date
    orderBy('last_time', 'desc'),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getNextTwentySeller(lastChatTime: number) {
  const q = query(
    messagesRef,
    where('buyer_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
    orderBy('last_time', 'desc'),
    where('last_time', '>=', 1688011200000), // launch date
    startAfter(lastChatTime),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getTwentyBuyerChats(lastChatTime?: number) {
  if (lastChatTime) {
    return await getNextTwentyBuyer(lastChatTime);
  }
  const q = query(
    messagesRef,
    where('seller_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('last_time', '>=', 1688011200000), // launch date
    orderBy('last_time', 'desc'),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getNextTwentyBuyer(lastChatTime: number) {
  const q = query(
    messagesRef,
    where('seller_id', '==', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('uids', 'array-contains', process.env.NEXT_PUBLIC_SUPPORT_ID),
    where('last_time', '>=', 1688011200000), // launch date
    orderBy('last_time', 'desc'),
    startAfter(lastChatTime),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getTwentyFlaggedChats(lastChatTime?: number) {
  if (lastChatTime) {
    return await getNextTwentyFlagged(lastChatTime);
  }
  const q = query(
    messagesRef,
    where('flagged', '==', true),
    orderBy('last_time', 'desc'),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getNextTwentyFlagged(lastChatTime: number) {
  const q = query(
    messagesRef,
    where('flagged', '==', true),
    orderBy('last_time', 'desc'),
    startAfter(lastChatTime),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function sendMessageToChat(
  message: ChatMessageDocument,
  chat: ChatDocument,
  currUserId?: string,
  product?: ProductDocument | null
) {
  const redactedMessage =
    currUserId === process.env.NEXT_PUBLIC_SUPPORT_ID ||
    chat.case_num ||
    product?.category === 'Dirt Bikes' ||
    chat.order_id
      ? message.content
      : redactPhonesAndEmailsFromStr(message.content, chat);
  message.content = redactedMessage;
  const newChat = { ...chat };
  if (!newChat.unread) {
    newChat.unread = {};
  }

  if (currUserId === process.env.NEXT_PUBLIC_SUPPORT_ID && chat.uids) {
    newChat.unread = {};
    newChat.unread[chat.buyer_id] = true;
    newChat.unread[chat.seller_id] = true;
    newChat.unread[process.env.NEXT_PUBLIC_SUPPORT_ID!] = false;
  } else if (currUserId) {
    const recipient = currUserId === chat.uids[0] ? chat.uids[1] : chat.uids[0];
    newChat.unread = { [recipient]: true, [currUserId]: false };
  }

  if (newChat.messages) {
    newChat.messages.push(message);
  } else {
    newChat.messages = [message];
  }
  const now = Date.now();
  message.created_at = now;
  newChat.last_time = now;

  const docRef = doc(messagesRef, chat.id);
  const res = chatSchema.safeParse(newChat);
  if (res.success) {
    await setDoc(docRef, res.data);
  } else {
    logError(res.error, `Zod Error Chat:${chat.id} ${res.error.toString()}`);
    await setDoc(docRef, newChat);
  }
  return newChat;
}

export async function createChat(chat: ChatInput) {
  const docRef = doc(messagesRef);
  const now = Date.now();
  chat.id = docRef.id;
  chat.last_time = now;
  chat.created_at = now;
  if (
    process.env.NEXT_PUBLIC_SUPPORT_ID &&
    chat.uids?.includes(process.env.NEXT_PUBLIC_SUPPORT_ID) &&
    !chat.case_num
  ) {
    chat.case_num = await getNextCaseNumber();
  }
  const res = chatSchema.safeParse(chat);
  if (res.success) {
    await setDoc(docRef, res.data);
  } else {
    logError(res.error, `Zod Error Chat:${chat.id} ${res.error.toString()}`);
    await setDoc(docRef, chat);
  }
  return docRef.id;
}

export async function getChat(chat_id: string) {
  const docRef = doc(messagesRef, chat_id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ChatDocument;
  } else {
    return null;
  }
}

export async function getNextCaseNumber() {
  const docRef = doc(messagesRef, 'count');
  const docSnap = await getDoc(docRef);
  const data = docSnap.data() as any as { count: number };
  await updateDoc(docRef, { count: data.count + 1 } as any);
  return data.count + 1;
}

export async function markAsRead(chat_id: string, uid: string) {
  const docRef = doc(messagesRef, chat_id);
  const key = `unread.${uid}`;
  await updateDoc(docRef, { [key]: false });
}

export async function markAsUnread(chat_id: string, uid: string) {
  const docRef = doc(messagesRef, chat_id);
  const key = `unread.${uid}`;
  await updateDoc(docRef, { [key]: true });
}

export async function flagChat(chat_id: string, flagged: boolean) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, { flagged });
}

export async function getFlaggedChats() {
  try {
    const q = query(
      messagesRef,
      where('flagged', '==', true),
      orderBy('last_time', 'desc')
    );
    const docs = await getDocs(q);
    return docs.docs.map((doc) => doc.data());
  } catch (e) {
    logError(e as Error);
    return [];
  }
}

export async function hideChat(chat_id: string, uid: string) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, {
    uids: arrayRemove(uid),
    [`unread.${uid}`]: deleteField(),
  });
}

export async function deleteMessage(
  chat_id: string,
  message: ChatMessageDocument
) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, { messages: arrayRemove(message) });
}

export async function uploadChatImage(
  data: Blob,
  user_id: string,
  chat_id: string
) {
  const path = `${user_id}/messages/${chat_id}/${Date.now()}`;
  const imagesRef = ref(storage, path);
  const res = await uploadBytes(imagesRef, data);
  const download_url = await getDownloadURL(res.ref);
  return { download_url, path: res.metadata.fullPath };
}

export async function contactUs(form: ContactUsForm) {
  const res = await httpsCallable<ContactUsForm, { data: 'success' }>(
    functions,
    FirebaseCallable.contactUs
  )(form);
  return res.data.data;
}

export async function subscribeToList(email: string, listId?: string) {
  const res = await httpsCallable<{ email: string; listId?: string }>(
    functions,
    FirebaseCallable.subscribeToList
  )({ email, listId });
  return res.data;
}

export async function isSubscribedToList({
  email,
  listId,
}: {
  email: string;
  listId: string;
}) {
  try {
    const res = await httpsCallable<{ email: string; listId: string }>(
      functions,
      FirebaseCallable.isSubscribed
    )({ email, listId });
    return res.data;
  } catch (e) {
    logError(e as Error);
    return false;
  }
}

// Optional chat argument to flag a chat
export function redactPhonesAndEmailsFromStr(str: string, chat?: ChatDocument) {
  const res = findPhoneNumbersInText(str, 'US');
  const hasForbiddenWord = new RegExp(
    /^.*(venmo|zelle|paypal|pay pal|cash app|cashapp|instagram|facebook|imessage|ebay|email).*$/
  ).test(str.toLowerCase());

  if (res.length || hasForbiddenWord) {
    // TODO: Send message from support once we know this works
    // const message = new ChatMessage(
    //   this.supportId,
    //   'Please note buying or selling outside of MX Locker is a violation of our policies and will result in the ban of your account.'
    // );
    // sendMessageToChat(chat.id, message);
    if (chat) {
      const messsage = {
        uid: process.env.NEXT_PUBLIC_SUPPORT_ID,
        content:
          'Please note buying or selling outside of MX Locker is a violation of our policies and will result in the ban of your account.',
        created_at: Date.now(),
      } as ChatMessageDocument;
      setTimeout(() => {
        sendMessageToChat(messsage, chat);
      }, 1000);
      flagChat(chat.id!, true);
    }
    res.forEach((r) => {
      const sub = str.substring(r.startsAt, r.endsAt);
      const redacted = new Array(sub.length).fill('█').join('');
      str = str!.replace(sub, redacted);
    });
  }
  const emailRegex = /\w+@\w+\.\w{2,3}/g;
  str = str.replace(emailRegex, '████████');
  return str;
}

export async function getAllUnreadMessages() {
  // get all unread messages for support
  const q = query(
    messagesRef,
    where(`unread.O4lXAvXpd1ahfKeYFPGE51JMeGI3`, '==', true)
  );
  const docs = await getDocs(q);
  // is_auto != false requires a query index
  return docs.docs.map((doc) => doc.data()).filter((c) => !c.is_auto);
}

export async function getSupportTicketsBetween(start: Date, end: Date) {
  try {
    const q = query(
      messagesRef,
      where('created_at', '>=', start.getTime()),
      where('created_at', '<=', end.getTime())
    );
    const docs = await getDocs(q);
    const chats = docs.docs.map((doc) => doc.data());
    return chats.filter((chat) => chat.case_num);
  } catch (e) {
    console.log((e as Error).message);
    return [];
  }
}

export async function initiateReturnRequest({
  itemsToReturn,
  order,
}: {
  itemsToReturn: ProductDocument[];
  order: OrderDocument;
}) {
  return await initiateReturn({
    order,
    products: itemsToReturn,
  });
}
